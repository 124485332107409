/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// eslint-disable-next-line import/no-extraneous-dependencies
import "firebase/remote-config";
import TagManager from "react-gtm-module";
import firebase from "gatsby-plugin-firebase";
import logger from "@graphite/logger";

export const onClientEntry = async () => {
	try {
		const remoteConfig = firebase.remoteConfig();
		await remoteConfig.fetchAndActivate();

		const gtmId = remoteConfig.getString("gtmId");
		if (gtmId && gtmId !== "null")
			TagManager.initialize({
				gtmId,
			});

		const logRocketId = remoteConfig.getString("logRocketId");
		const mixpanelId = remoteConfig.getString("mixpanelId");
		const yandexMetrikaId = remoteConfig.getString("yandexMetrikaId");
		const apiKeyAmplitudeKey = remoteConfig.getString("apiKeyAmplitudeKey");
		const sentryDNS = remoteConfig.getString("sentryDNS");

		const loggerConfig =
			process.env.NODE_ENV !== "production"
				? { browser: true }
				: {
						sentry: sentryDNS &&
							sentryDNS !== "null" && {
								DNS: sentryDNS,
							},
						fb: true,
						amplitude: apiKeyAmplitudeKey &&
							apiKeyAmplitudeKey !== "null" && {
								key: apiKeyAmplitudeKey,
							},
						mixpanel: mixpanelId &&
							mixpanelId !== "null" && {
								key: mixpanelId,
							},
						yandex: yandexMetrikaId &&
							yandexMetrikaId !== "null" && {
								key: yandexMetrikaId,
							},
						logRocket: logRocketId &&
							logRocketId !== "null" && {
								key: logRocketId,
							},
				  };

		logger.init(loggerConfig);
	} catch (e) {
		console.error(e);
	}
};

export const onRouteUpdate = ({ location }) => {
	const page = {
		"/": "home",
		"/privacy_policy/": "privacyPolicy",
		"/terms_of_use/": "TermsOfUse",
	}[location.pathname];

	logger.info(`${page}PageVisit`);
};

export default {};
